root {
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-table-caption-background: hsl(0, 0%, 97%);
  --ck-color-table-caption-text: hsl(0, 0%, 20%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
  --ck-todo-list-checkmark-size: 16px;
}
.ck-content {
  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
  }
  code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: 0.15em;
    border-radius: 2px;
  }
  .text-tiny {
    font-size: 0.7em;
  }
  .text-small {
    font-size: 0.85em;
  }
  .text-big {
    font-size: 1.4em;
  }
  .text-huge {
    font-size: 1.8em;
  }
  .marker-yellow {
    background-color: var(--ck-highlight-marker-yellow);
  }
  .marker-green {
    background-color: var(--ck-highlight-marker-green);
  }
  .marker-pink {
    background-color: var(--ck-highlight-marker-pink);
  }
  .marker-blue {
    background-color: var(--ck-highlight-marker-blue);
  }
  .pen-red {
    color: var(--ck-highlight-pen-red);
    background-color: transparent;
  }
  .pen-green {
    color: var(--ck-highlight-pen-green);
    background-color: transparent;
  }
  .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
    img {
      width: 100%;
    }
    > figcaption {
      display: block;
    }
  }
  .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;
    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      min-width: 100%;
    }
    > figcaption {
      display: table-caption;
      caption-side: bottom;
      word-break: break-word;
      color: var(--ck-color-image-caption-text);
      background-color: var(--ck-color-image-caption-background);
      padding: 0.6em;
      font-size: 0.75em;
      outline-offset: -1px;
    }
  }
  .image-inline {
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;
    picture {
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 100%;
    }
    img {
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 100%;
    }
  }
  .image-style-block-align-left {
    max-width: calc(100% - var(--ck-image-style-spacing));
    margin-left: 0;
    margin-right: auto;
  }
  .image-style-block-align-right {
    max-width: calc(100% - var(--ck-image-style-spacing));
    margin-right: 0;
    margin-left: auto;
  }
  .image-style-align-left {
    clear: none;
    float: left;
    margin-right: var(--ck-image-style-spacing);
  }
  .image-style-align-right {
    clear: none;
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }
  .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
  }
  .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }
  p {
    + {
      .image-style-align-left {
        margin-top: 0;
      }
      .image-style-align-right {
        margin-top: 0;
      }
      .image-style-side {
        margin-top: 0;
      }
    }
  }
  .image-inline.image-style-align-left {
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: var(--ck-inline-image-style-spacing);
    margin-right: var(--ck-inline-image-style-spacing);
  }
  .image-inline.image-style-align-right {
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: var(--ck-inline-image-style-spacing);
    margin-left: var(--ck-inline-image-style-spacing);
  }
  span[lang] {
    font-style: italic;
  }
  .todo-list {
    list-style: none;
    li {
      margin-bottom: 5px;
      .todo-list {
        margin-top: 5px;
      }
    }
    .todo-list__label {
      > input {
        -webkit-appearance: none;
        display: inline-block;
        position: relative;
        width: var(--ck-todo-list-checkmark-size);
        height: var(--ck-todo-list-checkmark-size);
        vertical-align: middle;
        border: 0;
        left: -25px;
        margin-right: -15px;
        right: 0;
        margin-left: 0;
        &::before {
          display: block;
          position: absolute;
          box-sizing: border-box;
          content: '';
          width: 100%;
          height: 100%;
          border: 1px solid hsl(0, 0%, 20%);
          border-radius: 2px;
          transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
        }
        &::after {
          display: block;
          position: absolute;
          box-sizing: content-box;
          pointer-events: none;
          content: '';
          left: calc(var(--ck-todo-list-checkmark-size) / 3);
          top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
          width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
          height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
          border-style: solid;
          border-color: transparent;
          border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0;
          transform: rotate(45deg);
        }
      }
      > input[checked] {
        &::before {
          background: hsl(126, 64%, 41%);
          border-color: hsl(126, 64%, 41%);
        }
        &::after {
          border-color: hsl(0, 0%, 100%);
        }
      }
      .todo-list__label__description {
        vertical-align: middle;
      }
    }
  }
  .media {
    clear: both;
    margin: 0.9em 0;
    display: block;
    min-width: 15em;
  }
  .page-break {
    position: relative;
    clear: both;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      content: '';
      position: absolute;
      border-bottom: 2px dashed hsl(0, 0%, 77%);
      width: 100%;
    }
  }
  .page-break__label {
    position: relative;
    z-index: 1;
    padding: 0.3em 0.6em;
    display: block;
    text-transform: uppercase;
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
    font-size: 0.75em;
    font-weight: bold;
    color: hsl(0, 0%, 20%);
    background: hsl(0, 0%, 100%);
    box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .table {
    .ck-table-resized {
      table-layout: fixed;
    }
    table {
      overflow: hidden;
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      height: 100%;
      border: 1px double hsl(0, 0%, 70%);
      td {
        min-width: 2em;
        padding: 0.4em;
        border: 1px solid hsl(0, 0%, 75%);
      }
      th {
        min-width: 2em;
        padding: 0.4em;
        border: 1px solid hsl(0, 0%, 75%);
        font-weight: bold;
        background: hsla(0, 0%, 0%, 5%);
      }
    }
    td {
      position: relative;
    }
    th {
      position: relative;
    }
    margin: 0.9em auto;
    display: table;
    > figcaption {
      display: table-caption;
      caption-side: top;
      word-break: break-word;
      text-align: center;
      color: var(--ck-color-table-caption-text);
      background-color: var(--ck-color-table-caption-background);
      padding: 0.6em;
      font-size: 0.75em;
      outline-offset: -1px;
    }
  }
  pre {
    padding: 1em;
    color: hsl(0, 0%, 20.8%);
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;
    code {
      background: unset;
      padding: 0;
      border-radius: 0;
    }
  }
  hr {
    margin: 15px 0;
    height: 4px;
    background: hsl(0, 0%, 87%);
    border: 0;
  }
  ol {
    list-style-type: decimal;
    ol {
      list-style-type: lower-latin;
      ol {
        list-style-type: lower-roman;
        ol {
          list-style-type: upper-latin;
          ol {
            list-style-type: upper-roman;
          }
        }
      }
    }
  }
  ul {
    list-style-type: circle;
    ul {
      list-style-type: disc;
      ul {
        list-style-type: square;
        ul {
          list-style-type: square;
        }
      }
    }
  }
  .mention {
    background: var(--ck-color-mention-background);
    color: var(--ck-color-mention-text);
  }
}
.ck-content[dir='rtl'] {
  blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%);
  }
  .table {
    th {
      text-align: right;
    }
  }
}
.ck-content[dir='ltr'] {
  .table {
    th {
      text-align: left;
    }
  }
}
@media print {
  .ck-content {
    .page-break {
      padding: 0;
      &::after {
        display: none;
      }
    }
  }
}
.ck-content {
  // Start copy from https://github.com/Kyons-VN/custom-ckeditor/blob/main/sample/kyons_content_style.css
  h6,
  h7,
  h8 {
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-wrap: break-word;
    display: block;
  }
  h6,
  .ck.ck-button.ck-heading_heading6 {
    font-size: 18px;
  }
  h7,
  .ck.ck-button.ck-heading_heading7 {
    font-size: 16px;
    font-weight: bold;
  }
  h8,
  .ck.ck-button.ck-heading_heading8 {
    font-size: 14px;
    font-weight: bold;
  }
  a {
    color: #fb7200;
  }
  a:hover,
  a:visited:hover {
    color: #315d93;
  }
  a:visited {
    color: #de6501;
  }
  note,
  .ck.ck-button.ck-heading_note,
  customcaption,
  .ck.ck-button.ck-heading_caption {
    font-size: 12px;
  }
  customcaption,
  .ck.ck-button.ck-heading_customcaption {
    text-transform: uppercase;
  }
  .text-primaryBlue-500 {
    color: #2c3d5b;
  }
  .text-secondaryBlue-500 {
    color: #2c3d5b;
  }
  .text-lightBlue1 {
    color: #06a5ff;
  }
  .text-lightBlue2 {
    color: #27b1ff;
  }
  .text-lightBlue4 {
    color: #5bc3fe;
  }
  .text-lightBlue5 {
    color: #98d6fa;
  }
  .text-lightBlue6 {
    color: #b6e5ff;
  }
  .text-lightBlue7 {
    color: #d0eeff;
  }
  .text-white {
    color: #ffffff;
  }
  .text-black {
    color: #000000;
  }
  .ck-content .table table {
    border: 1px solid transparent;
    border-collapse: separate;
  }
  .ck-content .table table th {
    background: #b6e5ff;
    font-weight: 700;
    border: none;
    color: #2c3d5b;
    border: 1px solid #b6e5ff;
  }
  .ck-content .table table tr td {
    border-color: #cbd5e1;
    border-left: none;
    border-right: none;
    border-top: none;
  }
  .ck-content .table table thead tr:first-child th:first-child {
    border-top-left-radius: 0.5rem;
  }
  .ck-content .table table thead tr:first-child th:last-child {
    border-top-right-radius: 0.5rem;
  }
  .ck-content .table table td,
  .ck-content .table table th {
    padding: 0.5rem;
  }
  .ck-content .table table tbody tr {
    border: 1px solid #cbd5e1;
    border-top: none;
    border-left-color: #b6e5ff;
    border-right-color: #b6e5ff;
  }
  .table table tbody tr td:first-child {
    border-left: 1px solid #b6e5ff;
  }
  .table table tbody tr td:last-child {
    border-right: 1px solid #b6e5ff;
  }
  // End copy

  // Custom for Student App
  ul {
    margin: inherit;
    padding: inherit;
    list-style-type: disc;

    ul {
      list-style-type: circle;
    }
  }
  @media (max-width: 767px) {
    .image.image-style-side,
    .image.image-style-align-right,
    .image.image-style-align-left,
    .image-inline.image-style-align-left,
    .image-inline.image-style-align-right,
    .image.image-style-block-align-left,
    .image.image-style-block-align-right,
    .image_resized {
      float: none;
      width: 100% !important;
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
      clear: both;
    }
  }
}
