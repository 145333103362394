// Design System
html,
body {
  @apply m-0 h-full w-full flex flex-col;
  &.outline * {
    outline: darkseagreen solid 1px;
  }
}
@media (pointer: fine) {
  @supports not (selector(::-webkit-scrollbar)) {
    * {
      scrollbar-color: #dadce0 transparent;
      scrollbar-gutter: auto;
      scrollbar-width: thin;
    }
  }
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-corner {
    background: transparent;
    height: 8px;
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
    color: #dadce0;
    min-height: 48px;
    min-width: 48px;
  }
  :hover::-webkit-scrollbar-thumb {
    background: content-box currentColor;
    color: #80868b;
  }
  ::-webkit-scrollbar-thumb:active {
    color: #5f6368;
  }
  ::-webkit-scrollbar-button {
    height: 0;
    width: 0;
  }
}
student-root,
.app-full {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow-y: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

[type='text'],
input:where(:not([type])),
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
select {
  border-color: var(--Border-default);
  background-color: var(--input-bg);
  &:active {
    border-color: var(--Border-active);
  }
  &:disabled {
    border-color: var(--Border-default);
  }
  &:focus {
    border-color: var(--Border-active);
  }
}

.hover\:on-screen:hover {
  --tw-bg-opacity: 1;
  background-color: var(--on-screen);
}

a {
  @apply cursor-pointer no-underline;
}

.text-primary {
  color: var(--color-primary, theme('colors.primaryBlue.500'));
}
.bg-primary {
  background-color: var(--color-primary, theme('colors.primaryBlue.500'));
}
.card {
  @apply rounded-lg p-6 flex flex-col gap-6;
  min-width: 324px;
  min-height: 324px;
  transition: all ease 0.2s;
  &.extended {
    @apply relative;
    &::before {
      @apply bg-blueGray-50 absolute border-dashed border-orange border-2 w-full h-full rounded-lg top-0 left-0;
      content: '';
      display: block;
      z-index: 0;
    }
    .card {
      z-index: 1;
    }
  }
  &:hover {
    @apply border-orange shadow-3;
  }
}
h1 {
  @apply text-primaryBlue-500;
  font-size: 36px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 22px;
}
h6 {
  font-size: 18px;
}
.h7 {
  @apply text-base;
}
.h8 {
  font-size: 14px;
  font-weight: 800;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h7,
.h8 {
  @apply m-0 font-bold;
  font-weight: 700;
  line-height: 150%;
  &.semi {
    font-weight: 600;
  }
}
p {
  margin-bottom: 0.625rem;
}
small {
  font-size: 0.75rem;
}
.svg.hover\:stroke-orange:hover > path {
  @apply stroke-orange;
}

select {
  @apply border rounded-md;
}

// Tailwindcss helper
.pb-full {
  padding-bottom: 100%;
}
.w-110full {
  width: calc(100% + 1.25rem);
}
.h-110full {
  height: calc(100% + 1.25rem);
}

// Form
input,
button {
  @apply border-solid inline-flex;
}
input {
  &[type='text'],
  &[type='password'],
  &[type='number'] {
    @apply h-[40px] border rounded-md shadow-none outline-none;
    &:focus {
      @apply outline-none shadow-none ring-offset-0 ring-transparent #{!important};
    }
  }
}
form:not(.no-validate) {
  input.ng-dirty.ng-invalid:not(.no-validate) {
    @apply border-red-0;
    &::placeholder {
      @apply text-red-0;
    }
  }
}

p {
  @apply mb-4;
}

.error {
  @apply px-3 mt-1;
}

.mydp {
  @apply border-black border rounded-md border-r-2 #{!important};

  input[ngtype='text'] {
    @apply py-2 px-3;
    font-family: 'Montserrat', sans-serif !important;
  }
}

.small-card {
  width: 256px;
  height: 256px;
  @apply rounded-md items-center text-blueGray-400 flex p-6 flex-shrink-0 transition-shadow ease-linear;
}

.carousel-caption {
  top: 5.625rem;
  color: var(--primaryBlue);
}
.mat-table {
  font-family: 'Montserrat', sans-serif;
}
table {
  @apply rounded-tl-[4px] rounded-tr-[4px] overflow-hidden;
  thead > tr > th.mat-header-cell {
    @apply bg-primaryBlue-500 text-white font-bold;
  }
}
.cdk-overlay-container .mat-dialog-container {
  @apply rounded-lg;
}
.col {
  @apply flex flex-col;
}

.caption,
caption {
  @apply text-xs;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar,
.katex-html {
  display: none; /* Safari and Chrome */
}
.tab-btn {
  @apply h-[40px] flex items-center justify-center text-blueGray-500 rounded-2xl cursor-pointer font-bold px-2 py-[10px];
  &.active {
    @apply bg-white text-orange;
  }
  &:hover {
    @apply bg-lightOrange-4 text-orange;
  }
}
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
object,
svg {
  pointer-events: none;
}

.tooltip-wrapper {
  .tooltip-container {
    @apply relative z-[100];
    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      box-sizing: border-box;
      transform-origin: 0 0;
      transform: rotate(-45deg);
      z-index: 0;
      @apply border-4 border-lightBlue-1;
    }
  }
}
.caret-left::before,
.caret-left::after {
  margin-left: -6px;
  top: calc(50% - 3px);
  left: 0;
}

.caret-left::before {
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.caret-left::after {
  border-color: #fff transparent transparent #fff;
}

.caret-right::before,
.caret-right::after {
  top: calc(50% - 3px);
  right: 0;
  margin-right: -3px;
}

.caret-right::before {
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.caret-right::after {
  border-color: transparent #fff #fff transparent;
}

.caret-up::before,
.caret-up::after {
  left: 50%;
  top: 0;
  left: var(--caret-left);
}

.caret-up::before {
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.caret-up::after {
  border-color: #fff #fff transparent transparent;
}

.caret-down::before,
.caret-down::after {
  right: 50%;
  top: 100%;
  right: var(--caret-right);
}

.caret-down::before {
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.caret-down::after {
  border-color: transparent transparent #fff #fff;
}

kyonsvn-tutorial label.radio {
  @apply bg-white;
}

.xs-hidden {
  @apply hidden sm:flex;
}
.tab-wrapper {
  @apply flex w-full md:w-auto justify-center gap-2;
  .tab-item {
    @apply flex md:flex-row flex-col justify-center items-center font-semibold gap-2 px-3 py-2 md:rounded-b-none rounded-lg cursor-pointer w-[120px] md:w-auto text-xs md:text-sm;
    .i {
      @apply w-5 h-5 text-xl;
    }
  }
  &.orange .tab-item {
    &:not(.active) {
      @apply md:hover:bg-lightOrange-4 md:bg-blueGray-200;
    }
    &.active {
      @apply md:text-orange bg-lightOrange-4 md:bg-white text-orange;
    }
  }
  &.lightBlue .tab-item {
    &:not(.active) {
      @apply md:hover:bg-lightBlue-6 md:bg-blueGray-200;
    }
    &.active {
      @apply md:text-lightBlue-1 bg-lightBlue-6 md:bg-white text-lightBlue-1;
    }
  }
}

ul.check-list,
.check-list-wrapper ul {
  @apply pl-4;

  & > li {
    @apply pl-2 mt-2;
    line-height: 150%;
    &::marker {
      content: $icon-Check;
      font-family: Kyons-Icon-Fonts;
    }
  }
}
// [type='text']:focus,
// input:where(:not([type])):focus,
// [type='email']:focus,
// [type='url']:focus,
// [type='password']:focus,
// [type='number']:focus,
// [type='date']:focus,
// [type='datetime-local']:focus,
// [type='month']:focus,
// [type='search']:focus,
// [type='tel']:focus,
// [type='time']:focus,
// [type='week']:focus,
// [multiple]:focus,
// textarea:focus,
// select:focus {
//   @apply outline-none shadow-none ring-0;
// }
.scrollable-content {
  @apply overflow-y-auto w-full h-full;
}
.scrollable-content::-webkit-scrollbar {
  display: none;
}

flutter-view input:where(:not([type])):focus {
  @apply outline-none shadow-none ring-0;
}

li.drop-menu-item a {
  @apply flex h-16 p-6 no-underline text-primaryBlue-500;
}
// .outline-green {
//   @apply text-darkEmerald border-darkEmerald;
// }
// .outline-red {
//   @apply text-red-0 border-red-0;
// }
// .outline-green.fill-green {
//   @apply bg-darkEmerald text-white;
// }
// .outline-red.fill-red {
//   @apply bg-red-0 text-white;
// }
.outline-green.fill-black,
.outline-red.fill-black {
  @apply bg-primaryBlue-500 text-white;
}
.btn.fill-black {
  @apply bg-primaryBlue-500 border-primaryBlue-500;
  &.fill-blue {
    @apply bg-lightBlue-1 border-lightBlue-1;
  }
}
.btn-icon {
  &.fill-blue {
    @apply bg-lightBlue-1 border-lightBlue-1;
  }
}
div[data-tooltip-content] label {
  background-color: white;
  color: var(--color-primaryBlue);
}
.lesson-loading {
}
.line-through {
  text-decoration-thickness: from-font;
}
.compact {
  @apply pt-14;
  .side-bar {
    &.show-sm {
      @apply flex absolute z-[100] top-0 left-0 right-0 bottom-0 p-4 xl:w-auto bg-white;
    }
    @apply xl:hidden;
  }
}
.side-bar {
  @apply xl:flex hidden flex-col flex-1 gap-4 xl:w-[308px] h-full xl:relative;
  &.show-sm {
    @media screen and (max-width: 1279px) {
      @apply flex fixed z-[100] top-0 left-0 right-0 bottom-0 p-4;
    }
  }
  ul > li {
    @apply gap-1 flex flex-col;
    & > ul > li {
      .chat-name {
        @apply flex-1 line-clamp-1 text-ellipsis;
        text-decoration: none !important;
      }
    }
  }
}
.sidenav-with-scrollable-list {
  @apply w-full h-full overflow-hidden grid grid-cols-1 grid-rows-[min-content_1fr];
  // width: 100%;
  // overflow: hidden;
  // display: grid;
  // grid-template-rows: min-content 1fr;
  // grid-template-columns: minmax(0, 1fr);
}
.history {
  @apply flex-1 flex flex-col gap-4;
}
.sidenav-with-scrollable-list {
  @apply gap-4;
}
ul.left-list {
  @apply flex flex-col gap-2 overflow-auto;
  .side-bar-item {
    @apply flex items-center rounded-md p-2;
    &:hover .opacity-0 {
      @apply opacity-100;
    }
  }
}
.prefix-icon {
  @apply relative;
  i:nth-child(1) {
    @apply absolute top-3 left-3 text-[18px];
  }
  input {
    @apply pl-8;
  }
}
[class*=' icon-'] {
  &.icon.large,
  &.large {
    @apply text-[32px];
  }
  &.icon.small,
  &.small {
    @apply text-[16px];
  }
}
.chatbox {
  @apply relative w-full h-full overflow-auto grid grid-cols-1 grid-rows-[min-content_1fr] max-w-[720px];
  .body {
    @apply w-full h-full pb-10;
    .message.right .hover-actions {
      left: auto;
    }
  }
  .ask {
    .ask-rounded-border {
      @apply flex relative flex-wrap overflow-hidden;
    }
  }
  .content {
    .hover-actions {
      @apply absolute z-10 left-[-2.25rem] right-0 pl-9 bottom-[-36px] flex gap-1 overflow-hidden h-0;
      .like .active {
        @apply text-emerald-0;
      }
      .dislike .active {
        @apply text-blueGray-500;
      }
    }
    .box.hover .hover-actions,
    .box:hover .hover-actions {
      transition: height 0.2s ease-in-out;
      transition-delay: 0.5s;
      @apply h-9;
    }
  }
}
.body .message.right .box.shine,
.shine,
.btn.shine,
.btn-icon.shine {
  // background-color: #f6f7f8;
  // background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  // background-repeat: no-repeat;
  background-size: 2000px 104px;
  display: inline-block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  &.delay-1 {
    animation-delay: 0.1s;
  }
  &.delay-2 {
    animation-delay: 0.2s;
  }
  &.delay-3 {
    animation-delay: 0.3s;
  }
  &.delay-4 {
    animation-delay: 0.4s;
  }
}
.body .message.right .box.shine,
.body .message.left .box.shine {
  width: 100%;
}
@keyframes placeholderShimmer {
  0% {
    background-position: -600px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}
messages {
  overflow: hidden;
}
ng-component {
  @apply contents;
}
.displaySidebar {
  .hideOnShowSidebarRight {
    @apply hidden;
  }
}
dialog[open] {
  > div {
    @apply mx-auto rounded-2xl border max-w-[calc(100%_-_2rem)] p-4;
  }
  > #profileDialog {
    @apply bg-primaryBlue-500 text-white;
    i:not([class*='text-']) {
      @apply text-white;
    }
  }
  @apply w-full max-w-[443px] bg-transparent relative;
  * {
    outline: none;
  }
  &.large {
    @apply max-w-[1122px] flex flex-col items-center;
  }
  &.transparent::backdrop {
    background: transparent;
  }
}
image-cropper {
  padding: 0 !important;
}
@mixin stroke($color: #000, $size: 1px) {
  text-shadow: -#{$size} -#{$size} 0 $color, 0 -#{$size} 0 $color, #{$size} -#{$size} 0 $color, #{$size} 0 0 $color,
    #{$size} #{$size} 0 $color, 0 #{$size} 0 $color, -#{$size} #{$size} 0 $color, -#{$size} 0 0 $color;
}
.week-task {
  @apply flex justify-between relative lg:min-w-[700px] w-full;
  .week-date {
    @apply flex flex-col gap-2 md:w-[68px] items-center w-[calc((100%_-_18px)_/_7)];
    .task-block {
      @apply flex flex-col aspect-square w-full rounded justify-center items-center p-2 z-[1];
      img {
        @apply max-w-[24px] max-h-[24px] w-2/3 h-2/3 aspect-square;
      }
    }
    .task-pointer {
      &::before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='16' viewBox='0 0 24 16' fill='none'%3E%3Cpath d='M13.5316 15.1761C12.7323 16.1278 11.2677 16.1279 10.4684 15.1761L0.903276 3.78607C-0.189488 2.48482 0.735618 0.499877 2.43485 0.499878L21.5652 0.499879C23.2644 0.499879 24.1895 2.48482 23.0967 3.78607L13.5316 15.1761Z' fill='%23FB7200'/%3E%3C/svg%3E");
        @apply absolute top-[-12px];
      }
    }
    .date-name {
      @apply w-max;
    }
  }
  .date-devider {
    @apply absolute w-[calc((100%_-_476px)/6_+_16px)] h-1 top-[32px];
    left: calc((var(--i) + 1) * 68px + var(--i) * (100% - 476px) / 6);
  }
}

.task-list {
  @apply w-fit flex gap-4;
  .task-item {
    @apply w-[384px] flex flex-col justify-between items-center shadow-2 transition;
    &.system {
      background: url('/assets/images/system-task-bg.svg') no-repeat center center / 100%;
      background-size: cover;
      .progress-bar {
        .bar-bg {
          @apply bg-lightOrange-4;
        }
        .bar-running {
          @apply bg-darkOrange-1;
          .bar-reflection {
            @apply bg-lightOrange-1;
          }
        }
        .bar-value {
          @include stroke(var(--color-orange), 1px);
        }
      }
    }
    &.admin {
      background: url('/assets/images/admin-task-bg.svg') no-repeat center center / 100%;
      background-size: cover;
      .progress-bar {
        .bar-bg {
          @apply bg-lightBlue-6;
        }
        .bar-running {
          @apply bg-lightBlue-1;
          .bar-reflection {
            @apply bg-lightBlue-3;
          }
        }
        .bar-value {
          @include stroke(var(--color-lightBlue-1), 1px);
        }
      }
    }
    .task-time {
      @apply inline-flex gap-2 items-center;
    }
    .progress-bar {
      @apply w-full relative;
      .bar-bg {
        @apply w-full h-[25px] rounded-2xl absolute top-0 left-0;
      }
      .bar-running {
        @apply rounded-2xl h-[25px] relative max-w-[100%];
        .bar-reflection {
          @apply rounded-2xl h-[9px] left-1 right-1 top-1 absolute;
        }
      }
      .bar-value {
        @apply absolute left-[50%] top-0.5 text-white;
      }
    }
  }
}
image-cropper.image-cropper {
  @apply max-h-[300px];
  .ngx-ic-cropper {
    .ngx-ic-resize .ngx-ic-square {
      @apply w-[10px] h-[10px] aspect-square #{!important};
    }
  }
  img.ngx-ic-source-image {
    @apply mx-auto;
  }
}
.workspace-item {
  @apply col lg:gap-4 p-4 rounded-2xl border justify-end;
  &.new {
    @apply border-dashed justify-start;
    .icon {
      @apply lg:p-3;
    }
  }
  caption {
    @apply text-left;
  }
}
.note-item {
  @apply rounded-2xl p-4 gap-2.5;
}
.workspace-item,
.note-item {
  flex-basis: 180px;
  aspect-ratio: 9/7;
  @apply flex-shrink-0 lg:h-[140px] lg:w-[180px];
}
.comeback-button {
  @apply relative overflow-hidden;
}
.chip {
  @apply rounded-full p-2 whitespace-nowrap max-w-[200px] flex items-center gap-4;
  span {
    @apply inline-flex flex-1 overflow-hidden max-w-[160px] items-center gap-2 text-[0.75rem];
  }
  button {
    @apply w-4 h-4 flex items-center;
    i {
      @apply w-4 h-4 text-[1rem];
    }
  }
  &.orange {
    @apply border-2 border-lightOrange-1 border-opacity-50 bg-lightOrange-4 bg-opacity-20;
    span:first-child {
      i {
        @apply text-lightOrange-1 text-opacity-50;
      }
    }
    i.icon-XClose {
      @apply text-blueGray-400;
    }
  }
}
// .loading {
//   @apply flex items-center justify-center w-full h-full;
// }
.chatbox.bg-blue {
  grid-template-rows: 100%;
}
@media screen and (min-width: 1280px) {
  .chat:not(.compact) .chatbot {
    grid-template-columns: 308px minmax(0, 1fr);
  }
}
@media screen and (max-width: 1279px) {
  .chat .chatbot {
    grid-template-columns: 100%;
  }
}
// .chat-models {
//   transition: width 0.3s ease-in-out;
//   z-index: 1;
//   @apply absolute left-[9px] top-[2px] w-[40px] h-[40px];
//   &.hover,
//   &:hover {
//     @apply w-[90px];
//     button.active {
//       // Apply animation
//       animation: blink 1s ease-in-out infinite;
//     }
//   }
// }

// Animation blinking
@keyframes blink {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

.bg-secondary-gradient-to-bottom {
  background: linear-gradient(-90deg, #315d93, #2c3d5b);
  background: -moz-linear-gradient(-90deg, #315d93, #2c3d5b);
  background: -webkit-linear-gradient(-90deg, #315d93, #2c3d5b);
}
.source-header {
  @apply flex justify-between border-b items-center h-16 lg:px-0 px-4;
}
.workspace-actions {
  @apply grid gap-4 grid-cols-2 lg:grid-cols-4 grid-rows-1 w-full max-w-[720px] mx-auto;
  button {
    @apply col gap-2.5 rounded-lg p-4 text-left;
  }
}
file-selection {
  @apply contents;
}
kyonsvn-latex {
  ul {
    @apply list-disc;
  }
}
.file-manager .onClose {
  @apply hidden;
}
.listWrapper ul {
  @apply pl-5;
}

.dropdownMenu {
  @apply hidden md:flex;
}
.isShowDropdown {
  .dropdownMenu {
    @apply flex absolute top-2 left-2;
  }
}

.katex .katex-mathml msub > mo {
  line-height: 0;
}
kyonsvn-test-review {
  display: contents;
}
