:root,
::after {
  --color-primary: theme('colors.lightBlue.1');
  --color-secondary: theme('colors.teal.0');
}
.btn,
.btn-icon {
  &.primary {
    @apply bg-lightBlue-1 border-lightBlue-1 text-white;
    &.outlined {
      @apply bg-white text-lightBlue-1 border border-lightBlue-1;
    }
    &.link {
      @apply bg-transparent text-lightBlue-1 border-transparent shadow-none;
    }
  }
  &.secondary {
    @apply bg-teal-0 text-white;
    &.outlined {
      @apply bg-transparent border-teal-0 border text-teal-0;
    }
    &.link {
      @apply bg-transparent text-teal-0 border-transparent shadow-none;
    }
  }
}
[data-skin='default'][data-theme='dark'] {
  .shine {
    background-color: transparent;
    background-image: linear-gradient(
      to right,
      var(--color-primaryBlue-900) 0%,
      var(--color-primaryBlue) 30%,
      var(--color-primaryBlue-900) 100%
    );
    &.primary {
      background-image: linear-gradient(
        to right,
        theme('colors.lightBlue.4') 0%,
        theme('colors.lightBlue.2') 30%,
        theme('colors.lightBlue.4') 100%
      );
    }
    &.secondary {
      background-image: linear-gradient(
        to right,
        theme('colors.secondaryBlue.300') 0%,
        theme('colors.secondaryBlue.500') 30%,
        theme('colors.secondaryBlue.300') 100%
      );
    }
  }
  .tab-wrapper {
    &.primary .tab-item {
      &:not(.active) {
        @apply md:hover:bg-primaryBlue-600 md:bg-primaryBlue-700;
      }
      &.active {
        @apply md:text-lightBlue-1 bg-lightBlue-6 md:bg-primaryBlue-800 text-lightBlue-1;
      }
    }
  }
}
[data-skin='default'][data-theme='light'] {
  .shine {
    background-color: transparent;
    background-image: linear-gradient(
      to right,
      theme('colors.primaryBlue.300') 0%,
      theme('colors.primaryBlue.100') 30%,
      theme('colors.primaryBlue.300') 100%
    );
    &.orange,
    &.primary {
      background-image: linear-gradient(
        to right,
        theme('colors.lightBlue.4') 0%,
        theme('colors.lightBlue.2') 30%,
        theme('colors.lightBlue.4') 100%
      );
    }
    &.secondary {
      background-image: linear-gradient(
        to right,
        theme('colors.secondaryBlue.300') 0%,
        theme('colors.secondaryBlue.500') 30%,
        theme('colors.secondaryBlue.300') 100%
      );
    }
  }
  .tab-wrapper {
    &.primary .tab-item {
      &:not(.active) {
        @apply md:hover:bg-lightBlue-6 md:bg-blueGray-200;
      }
      &.active {
        @apply md:text-lightBlue-1 bg-lightBlue-6 md:bg-white text-lightBlue-1;
      }
    }
  }
}
