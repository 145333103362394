.btn {
  @apply rounded-xl h-[40px] px-[16px] py-[8px] flex items-center justify-center cursor-pointer whitespace-nowrap gap-2 font-[600] text-sm;
  &:not([class*='min-w-']) {
    @apply min-w-[147px];
  }
  i {
    @apply text-[24px];
  }
  &.large {
    @apply h-[48px] px-[16px] py-[12px];
  }
  &.md\:large {
    @apply h-[40px] px-[16px] py-[8px];
    @media screen and (min-width: 768px) {
      @apply h-[48px] px-[16px] py-[12px];
    }
  }
  &.small {
    @apply h-[36px] px-[8px] py-[10px];
    i {
      @apply text-[16px];
    }
  }
  &.hidden {
    display: none;
  }
}
.btn-icon {
  @apply rounded-xl h-[40px] aspect-square p-[8px] items-center justify-center cursor-pointer whitespace-nowrap font-[600];
  i {
    @apply text-[24px];
  }
  &.large {
    @apply h-[48px] p-[12px];
  }
  &.small {
    @apply h-[36px] p-[10px];
    i {
      @apply text-[18px];
    }
  }
  &.circle {
    @apply rounded-full h-[56px];
    &.large {
      @apply h-[64px] p-[12px];
    }
    &.small {
      @apply h-[40px] p-[10px];
    }
  }
}
.btn,
.btn-icon {
  // &.link {
  //   @apply rounded-none;
  // }
  &.orange {
    @apply bg-orange text-white border-orange;
    &.outlined {
      @apply bg-white text-orange border border-orange;
    }
    &.link {
      @apply bg-transparent text-orange border-transparent shadow-none;
    }
  }
  &.lightBlue {
    @apply bg-lightBlue-1 border-lightBlue-1 text-white;
    &.outlined {
      @apply bg-white text-lightBlue-1 border border-lightBlue-1;
    }
    &.link {
      @apply bg-transparent text-lightBlue-1 border-transparent shadow-none;
    }
  }
  &.blueGray {
    @apply bg-white border-blueGray-300 text-blueGray-500;
    &.outlined {
      @apply bg-white border-blueGray-300 border text-blueGray-500;
    }
    &.link {
      @apply bg-white text-blueGray-500 border-transparent shadow-none;
    }
  }
  &.darkEmerald {
    @apply bg-darkEmerald text-white;
    &.outlined {
      @apply bg-white border-darkEmerald border text-darkEmerald;
    }
    &.link {
      @apply bg-white text-darkEmerald border-transparent shadow-none;
    }
  }
  &.red {
    @apply bg-red-0 text-white;
    &.outlined {
      @apply bg-white border-red-0 border text-red-0;
    }
    &.link {
      @apply bg-white text-red-0 border-transparent shadow-none;
    }
  }
  &.white {
    @apply bg-white text-primaryBlue-500;
    &.outlined {
      @apply bg-transparent border-white border text-white;
    }
    &.link {
      @apply bg-transparent text-white border-transparent shadow-none;
    }
  }
  &.teal {
    @apply bg-teal-0 text-white;
    &.outlined {
      @apply bg-transparent border-teal-0 border text-teal-0;
    }
    &.link {
      @apply bg-transparent text-teal-0 border-transparent shadow-none;
    }
  }
}
// @media screen and (min-width: 768px) {
//   .btn,
//   .btn-icon {
//     &.hidden.md\:flex {
//       display: flex;
//     }
//   }
// }

.btn,
.btn-icon {
  &:not(.hidden) {
    @apply inline-flex;
  }
  &:hover:not(.link):not(:disabled) {
    @apply shadow-1;
  }
  &:hover:active {
    @apply shadow-none;
    &.orange {
      @apply text-lightOrange-3;
    }
    &.blueGray {
      @apply text-blueGray-300;
    }
    &.lightBlue {
      @apply text-lightBlue-3;
    }
    &.darkEmerald {
      @apply text-emerald-2;
    }
    &.red {
      @apply text-red-300;
    }
    &.white {
      @apply text-white;
    }
    &.secondary {
      @apply text-secondaryBlue-300;
    }
  }
}
.btn:disabled {
  @apply cursor-not-allowed;
  &:not(.outlined):not(.link) {
    @apply text-blueGray-400 border-blueGray-200;
    &:not(.shine) {
      @apply bg-blueGray-200;
    }
  }
  &.outlined,
  &.link {
    @apply text-blueGray-400 border-blueGray-400;
    &:not(.shine) {
      @apply bg-white;
    }
  }
  &.link * {
    @apply opacity-70;
  }
}

.btn-icon:disabled:not(.shine) {
  @apply cursor-not-allowed;
  &:not(.outlined):not(.link) {
    @apply bg-blueGray-200 text-blueGray-400 border-blueGray-200;
    &:not(.shine) {
      @apply bg-blueGray-200;
    }
  }
  &.outlined,
  &.link {
    @apply bg-transparent text-blueGray-200 border-transparent;
    &:not(.shine) {
      @apply bg-transparent;
    }
  }
  &.link * {
    @apply opacity-50;
  }
}

.btn {
  &.google {
    @apply bg-[#4285F4] text-white border border-[#4285F4];
    &:hover {
      @apply border-[#4285F4];
    }
    &:active {
      @apply text-white;
      @apply border-[#4285F4];
    }
    &:disabled {
      @apply bg-white text-blueGray-300 border-blueGray-300 #{!important};
    }
  }
  &.facebook {
    @apply bg-[#4267B2] text-white border border-[#4267B2];
    &:hover {
      @apply border-[#4267B2];
    }
    &:active {
      @apply text-white;
      @apply border-[#4267B2];
    }
    &:disabled {
      @apply bg-white text-blueGray-300 border-blueGray-300 #{!important};
    }
  }
}
