.question_block {
  margin: 10px 0;
}

.question_block .question-text {
  margin-bottom: 10px;
}

.select select {
  background: #ffffff;
  border: 1px solid #d0dae8;
  box-sizing: border-box;
  border-radius: 18px;
  padding: 5px 10px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: radio-container;
  appearance: none;
  background: url('/assets/images/dropdown.svg') no-repeat right 10px center;
  height: 37px;
}

.select select:required:invalid {
  color: #939cc8;
}

.select select option[value=''][disabled] {
  display: none;
}

.select select option {
  color: black;
}

.question_step .question_block {
  margin-bottom: 10px;
  width: 100%;
}

.date,
.time {
  border: 1px solid #d0dae8;
  box-sizing: border-box;
  border-radius: 18px;
  height: 37px;
  width: 170px;
  position: relative;
  margin-bottom: 10px;
}

.date {
  background: #fff url('/assets/images/icons/calendar.svg') left 10px center no-repeat;
}

.time {
  background: #fff url('/assets/images/icons/time.svg') left 10px center no-repeat;
}

.date .placeholder,
.time .placeholder {
  position: absolute;
  left: 40px;
  top: 10px;
}

.placeholder {
  color: #939cc8;
}

.date input[type='date'],
.time input[type='time'] {
  height: 37px;
  opacity: 0;
  width: 100%;
  position: relative;
  z-index: 1;
}

.date input[type='date']::-webkit-inner-spin-button,
.time input[type='time']::-webkit-inner-spin-button {
  display: none;
}

.date input[type='date']::-webkit-calendar-picker-indicator,
.time input[type='time']::-webkit-calendar-picker-indicator {
  width: 100%;
  background: transparent;
}

.checkbox,
.radio,
.nps-range label {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}

.checkbox > span,
.radio > span {
  color: var(--content-color);
  padding: 0.2rem 0.25rem;
}

.checkbox > input[type='checkbox'],
.radio > input[type='radio'] {
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  border: none;
  margin-left: 2px;
  margin-top: 4px;
  margin-right: 6px;
  box-shadow: none;
  outline-offset: 0;
  &:not(:focus) {
    outline: none;
  }
  &:focus-visible {
    border-radius: 4px;
    outline: 1px solid var(--color-primaryBlue);
  }
}

.radio > input[type='radio']:checked {
  background: url('/assets/icons/radio_on.svg') no-repeat;
}

.checkbox > input[type='checkbox']:checked {
  background: url('/assets/images/checkbox_checked.svg') no-repeat;
}
.checkbox.dark > input[type='checkbox']:checked {
  background: url('/assets/images/checkbox_checked_dark.svg') no-repeat;
}
.checkbox > input[type='checkbox']:not(:checked) {
  background: url('/assets/images/checkbox_normal.svg') no-repeat;
}
// .checkbox > input[type='checkbox'].ng-dirty.ng-invalid:not(:checked) {
//   background: url('/assets/images/checkbox_error.svg') no-repeat;
// }

.radio > input[type='radio']:not(:checked) {
  background: url('/assets/icons/radio_off.svg') no-repeat;
}

.checkbox > input[type='checkbox']:checked + span::before,
.radio > input[type='radio']:checked + span::before {
  // content: '';
  // display: block;
  // text-align: center;
  // position: absolute;
  // top: 3px;
  // width: 16px;
  // height: 17px;
}

.checkbox > input[type='checkbox']:checked + span::before {
  left: 2px;
  // background: url('/assets/images/checkbox_checked.svg') no-repeat;
}

.radio > input[type='radio']:checked + span::before {
  left: 2px;
  background: url('/assets/icons/radio_on.svg') no-repeat;
}

.nps {
  margin: 10px 0;
}

.nps-range {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7.5px;
  height: 15px;
  position: relative;
}

.nps-range label {
  position: absolute;
  display: inline-flex;
  justify-content: space-between;
}

.nps-range label:nth-child(1) > input[type='checkbox']:checked ~ span::before {
  width: 100%;
  left: 0;
}

.nps-range label:nth-child(1) {
  width: 18%;
  z-index: 5;
}

.nps-range label:nth-child(2) {
  width: 38.5%;
  z-index: 4;
}

.nps-range label:nth-child(3) {
  width: 59%;
  z-index: 3;
}

.nps-range label:nth-child(4) {
  width: 79.5%;
  z-index: 2;
}

.nps-range label:nth-child(5) {
  width: 100%;
  z-index: 1;
}

.nps-range label:nth-child(2) > input[type='checkbox']:checked ~ span::before {
  width: 48%;
}

.nps-range input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 15px;
  border-radius: 2px;
}

.nps-range,
.nps-text {
  width: 49%;
}

.nps-range label:nth-child(5) > input:not(:checked) ~ span::before {
  background: var(--background-light-color);
}

.nps-range label > input[type='checkbox']:checked ~ span::before {
  background: var(--primary-color);
  -moz-box-shadow: 0px 2px 8px rgba(0, 25, 114, 0.3);
  -webkit-box-shadow: 0px 2px 8px rgba(0, 25, 114, 0.3);
  box-shadow: 0px 2px 8px rgba(0, 25, 114, 0.3);
}

.nps-range label:nth-child(2) > input + span + span::before {
  left: 53.245%;
}

.nps-range label:nth-child(2) > span::before {
  width: 46.755%;
}

.nps-range label:nth-child(3) > input + span + span::before {
  left: 34.68%;
}

.nps-range label:nth-child(3) > input + span + span + span::before {
  left: 69.36%;
}

.nps-range label:nth-child(3) > span::before {
  width: 30.62%;
}

.nps-range label:nth-child(4) > input + span + span::before {
  left: 25.625%;
}

.nps-range label:nth-child(4) > input + span + span + span::before {
  left: 51.25%;
}

.nps-range label:nth-child(4) > input + span + span + span + span::before {
  left: 76.875%;
}

.nps-range label:nth-child(4) > span::before {
  width: 23.125%;
}

.nps-range label:nth-child(5) > input + span + span::before {
  left: 20.5%;
}

.nps-range label:nth-child(5) > input + span + span + span::before {
  left: 41%;
}

.nps-range label:nth-child(5) > input + span + span + span + span::before {
  left: 61.5%;
}

.nps-range label:nth-child(5) > input + span + span + span + span + span::before {
  left: auto;
  right: 0;
  border-bottom-right-radius: 7.5px;
  border-top-right-radius: 7.5px;
}

.nps-range label:nth-child(5) > span::before {
  width: 18%;
}

.nps-range > label > span::before {
  content: '';
  display: block;
  text-align: center;
  position: absolute;
  top: 0;
  height: 15px;
  border-radius: 2px;
  z-index: 0;
}

.nps-range label > input + span::before {
  left: 0;
  border-bottom-left-radius: 7.5px;
  border-top-left-radius: 7.5px;
}

.time_frame_block:not([id='tfb_0']) .tfb_close {
  display: flex;
}

.time_frame_block .tfb_close {
  display: none;
}

.tfb_close::before {
  content: '';
  background: #fff url(/assets/images/icons/circle/close.svg) center center no-repeat;
  width: 24px;
  height: 24px;
}

p img.image_resized {
  @apply inline-block align-bottom;
}
