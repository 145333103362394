@import 'variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?4bx3ek') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?4bx3ek') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?4bx3ek##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
  // &:not([class*='text-']):not(.btn-icon i, .btn i) {
  //   font-size: 16px;
  // }
  &.small {
    @apply text-[16px];
  }
  &.large {
    @apply text-[32px];
  }
}

.icon-Add {
  &:before {
    content: $icon-Add;
  }
}
.icon-AddFile {
  &:before {
    content: $icon-AddFile;
  }
}
.icon-AddFriend {
  &:before {
    content: $icon-AddFriend;
  }
}
.icon-AddPhoto {
  &:before {
    content: $icon-AddPhoto;
  }
}
.icon-Alarm {
  &:before {
    content: $icon-Alarm;
  }
}
.icon-AlignCenter {
  &:before {
    content: $icon-AlignCenter;
  }
}
.icon-AlignLeft {
  &:before {
    content: $icon-AlignLeft;
  }
}
.icon-AlignRight {
  &:before {
    content: $icon-AlignRight;
  }
}
.icon-ArrowBackward {
  &:before {
    content: $icon-ArrowBackward;
  }
}
.icon-ArrowDown {
  &:before {
    content: $icon-ArrowDown;
  }
}
.icon-ArrowDownStop {
  &:before {
    content: $icon-ArrowDownStop;
  }
}
.icon-ArrowForward {
  &:before {
    content: $icon-ArrowForward;
  }
}
.icon-ArrowLeftStop {
  &:before {
    content: $icon-ArrowLeftStop;
  }
}
.icon-ArrowRightStop {
  &:before {
    content: $icon-ArrowRightStop;
  }
}
.icon-ArrowSelector {
  &:before {
    content: $icon-ArrowSelector;
  }
}
.icon-ArrowUp {
  &:before {
    content: $icon-ArrowUp;
  }
}
.icon-ArrowUpStop {
  &:before {
    content: $icon-ArrowUpStop;
  }
}
.icon-ATM {
  &:before {
    content: $icon-ATM;
  }
}
.icon-BankTransfer {
  &:before {
    content: $icon-BankTransfer;
  }
}
.icon-Bookmark {
  &:before {
    content: $icon-Bookmark;
  }
}
.icon-Bookmark-1 {
  &:before {
    content: $icon-Bookmark-1;
  }
}
.icon-BookmarkAdd {
  &:before {
    content: $icon-BookmarkAdd;
  }
}
.icon-BookmarkRemove {
  &:before {
    content: $icon-BookmarkRemove;
  }
}
.icon-BulletPoint {
  &:before {
    content: $icon-BulletPoint;
  }
}
.icon-Calendar {
  &:before {
    content: $icon-Calendar;
  }
}
.icon-CalendarViewDay {
  &:before {
    content: $icon-CalendarViewDay;
  }
}
.icon-CalendarViewMonth {
  &:before {
    content: $icon-CalendarViewMonth;
  }
}
.icon-CalendarViewWeek {
  &:before {
    content: $icon-CalendarViewWeek;
  }
}
.icon-Call {
  &:before {
    content: $icon-Call;
  }
}
.icon-CallTutor {
  &:before {
    content: $icon-CallTutor;
  }
}
.icon-Cancel {
  &:before {
    content: $icon-Cancel;
  }
}
.icon-Carousel {
  &:before {
    content: $icon-Carousel;
  }
}
.icon-Cash {
  &:before {
    content: $icon-Cash;
  }
}
.icon-Chart {
  &:before {
    content: $icon-Chart;
  }
}
.icon-Chat {
  &:before {
    content: $icon-Chat;
  }
}
.icon-Check {
  &:before {
    content: $icon-Check;
  }
}
.icon-ChevronDown {
  &:before {
    content: $icon-ChevronDown;
  }
}
.icon-ChevronLeft {
  &:before {
    content: $icon-ChevronLeft;
  }
}
.icon-ChevronRight {
  &:before {
    content: $icon-ChevronRight;
  }
}
.icon-ChevronUp {
  &:before {
    content: $icon-ChevronUp;
  }
}
.icon-Crop {
  &:before {
    content: $icon-Crop;
  }
}
.icon-Crown {
  &:before {
    content: $icon-Crown;
  }
}
.icon-DeviceLaptop {
  &:before {
    content: $icon-DeviceLaptop;
  }
}
.icon-Document {
  &:before {
    content: $icon-Document;
  }
}
.icon-Done {
  &:before {
    content: $icon-Done;
  }
}
.icon-DotPoints {
  &:before {
    content: $icon-DotPoints;
  }
}
.icon-DotsGrid {
  &:before {
    content: $icon-DotsGrid;
  }
}
.icon-DotsHorizontal {
  &:before {
    content: $icon-DotsHorizontal;
  }
}
.icon-DotsVertical {
  &:before {
    content: $icon-DotsVertical;
  }
}
.icon-Draw {
  &:before {
    content: $icon-Draw;
  }
}
.icon-Duplicate {
  &:before {
    content: $icon-Duplicate;
  }
}
.icon-Edit {
  &:before {
    content: $icon-Edit;
  }
}
.icon-EditText {
  &:before {
    content: $icon-EditText;
  }
}
.icon-Education {
  &:before {
    content: $icon-Education;
  }
}
.icon-Email {
  &:before {
    content: $icon-Email;
  }
}
.icon-Eraser {
  &:before {
    content: $icon-Eraser;
  }
}
.icon-Error {
  &:before {
    content: $icon-Error;
  }
}
.icon-Facebook {
  &:before {
    content: $icon-Facebook;
  }
}
.icon-File {
  &:before {
    content: $icon-File;
  }
}
.icon-File01 {
  &:before {
    content: $icon-File01;
  }
}
.icon-Filter {
  &:before {
    content: $icon-Filter;
  }
}
.icon-Folder {
  &:before {
    content: $icon-Folder;
  }
}
.icon-FolderOpen {
  &:before {
    content: $icon-FolderOpen;
  }
}
.icon-Google {
  &:before {
    content: $icon-Google;
  }
}
.icon-Group {
  &:before {
    content: $icon-Group;
  }
}
.icon-Hanbook {
  &:before {
    content: $icon-Hanbook;
  }
}
.icon-Help {
  &:before {
    content: $icon-Help;
  }
}
.icon-HintDocument {
  &:before {
    content: $icon-HintDocument;
  }
}
.icon-HintTutor {
  &:before {
    content: $icon-HintTutor;
  }
}
.icon-History {
  &:before {
    content: $icon-History;
  }
}
.icon-Home {
  &:before {
    content: $icon-Home;
  }
}
.icon-Image {
  &:before {
    content: $icon-Image;
  }
}
.icon-Info {
  &:before {
    content: $icon-Info;
  }
}
.icon-InforCircle {
  &:before {
    content: $icon-InforCircle;
  }
}
.icon-insertText {
  &:before {
    content: $icon-insertText;
  }
}
.icon-Inventory {
  &:before {
    content: $icon-Inventory;
  }
}
.icon-Keyboard {
  &:before {
    content: $icon-Keyboard;
  }
}
.icon-KeyboardHide {
  &:before {
    content: $icon-KeyboardHide;
  }
}
.icon-Label {
  &:before {
    content: $icon-Label;
  }
}
.icon-LessonContent {
  &:before {
    content: $icon-LessonContent;
  }
}
.icon-Lightbulb {
  &:before {
    content: $icon-Lightbulb;
  }
}
.icon-Lightbulb-1 {
  &:before {
    content: $icon-Lightbulb-1;
  }
}
.icon-LightTheme {
  &:before {
    content: $icon-LightTheme;
  }
}
.icon-LineWeight {
  &:before {
    content: $icon-LineWeight;
  }
}
.icon-Link {
  &:before {
    content: $icon-Link;
  }
}
.icon-Location {
  &:before {
    content: $icon-Location;
  }
}
.icon-Lock {
  &:before {
    content: $icon-Lock;
  }
}
.icon-Map {
  &:before {
    content: $icon-Map;
  }
}
.icon-MathCamera {
  &:before {
    content: $icon-MathCamera;
  }
}
.icon-MenuHamburger {
  &:before {
    content: $icon-MenuHamburger;
  }
}
.icon-MessageQuestionCircle {
  &:before {
    content: $icon-MessageQuestionCircle;
  }
}
.icon-MessageSmileSquare {
  &:before {
    content: $icon-MessageSmileSquare;
  }
}
.icon-MessageTextSquare {
  &:before {
    content: $icon-MessageTextSquare;
  }
}
.icon-MicOff {
  &:before {
    content: $icon-MicOff;
  }
}
.icon-MicOn {
  &:before {
    content: $icon-MicOn;
  }
}
.icon-Minimize {
  &:before {
    content: $icon-Minimize;
  }
}
.icon-MinusCircle {
  &:before {
    content: $icon-MinusCircle;
  }
}
.icon-Momo {
  &:before {
    content: $icon-Momo;
  }
}
.icon-More {
  &:before {
    content: $icon-More;
  }
}
.icon-NewChat {
  &:before {
    content: $icon-NewChat;
  }
}
.icon-NewFolder {
  &:before {
    content: $icon-NewFolder;
  }
}
.icon-NewLabel {
  &:before {
    content: $icon-NewLabel;
  }
}
.icon-NoteManagement {
  &:before {
    content: $icon-NoteManagement;
  }
}
.icon-Notification {
  &:before {
    content: $icon-Notification;
  }
}
.icon-OpenNew {
  &:before {
    content: $icon-OpenNew;
  }
}
.icon-Paypal {
  &:before {
    content: $icon-Paypal;
  }
}
.icon-PencilLine {
  &:before {
    content: $icon-PencilLine;
  }
}
.icon-Phone {
  &:before {
    content: $icon-Phone;
  }
}
.icon-Plus {
  &:before {
    content: $icon-Plus;
  }
}
.icon-PlusCircle {
  &:before {
    content: $icon-PlusCircle;
  }
}
.icon-Present {
  &:before {
    content: $icon-Present;
  }
}
.icon-Preview {
  &:before {
    content: $icon-Preview;
  }
}
.icon-Profile {
  &:before {
    content: $icon-Profile;
  }
}
.icon-ReadAll {
  &:before {
    content: $icon-ReadAll;
  }
}
.icon-Redo {
  &:before {
    content: $icon-Redo;
  }
}
.icon-Reload {
  &:before {
    content: $icon-Reload;
  }
}
.icon-Reset {
  &:before {
    content: $icon-Reset;
  }
}
.icon-Save {
  &:before {
    content: $icon-Save;
  }
}
.icon-Screenshot {
  &:before {
    content: $icon-Screenshot;
  }
}
.icon-Search {
  &:before {
    content: $icon-Search;
  }
}
.icon-Send {
  &:before {
    content: $icon-Send;
  }
}
.icon-Send01 {
  &:before {
    content: $icon-Send01;
  }
}
.icon-Send02 {
  &:before {
    content: $icon-Send02;
  }
}
.icon-Settings {
  &:before {
    content: $icon-Settings;
  }
}
.icon-Share {
  &:before {
    content: $icon-Share;
  }
}
.icon-Shop {
  &:before {
    content: $icon-Shop;
  }
}
.icon-Sidebar {
  &:before {
    content: $icon-Sidebar;
  }
}
.icon-SignOut {
  &:before {
    content: $icon-SignOut;
  }
}
.icon-Sort {
  &:before {
    content: $icon-Sort;
  }
}
.icon-Star {
  &:before {
    content: $icon-Star;
  }
}
.icon-Submit {
  &:before {
    content: $icon-Submit;
  }
}
.icon-Subscription {
  &:before {
    content: $icon-Subscription;
  }
}
.icon-Substract {
  &:before {
    content: $icon-Substract;
  }
}
.icon-Swap {
  &:before {
    content: $icon-Swap;
  }
}
.icon-Target01 {
  &:before {
    content: $icon-Target01;
  }
}
.icon-Target02 {
  &:before {
    content: $icon-Target02;
  }
}
.icon-Target03 {
  &:before {
    content: $icon-Target03;
  }
}
.icon-Target04 {
  &:before {
    content: $icon-Target04;
  }
}
.icon-Target05 {
  &:before {
    content: $icon-Target05;
  }
}
.icon-Test {
  &:before {
    content: $icon-Test;
  }
}
.icon-TextBold {
  &:before {
    content: $icon-TextBold;
  }
}
.icon-TextItalic {
  &:before {
    content: $icon-TextItalic;
  }
}
.icon-TextUnderlined {
  &:before {
    content: $icon-TextUnderlined;
  }
}
.icon-Theme {
  &:before {
    content: $icon-Theme;
  }
}
.icon-Thumbnail {
  &:before {
    content: $icon-Thumbnail;
  }
}
.icon-ThumbsDown {
  &:before {
    content: $icon-ThumbsDown;
  }
}
.icon-ThumbsUp {
  &:before {
    content: $icon-ThumbsUp;
  }
}
.icon-Tiktok {
  &:before {
    content: $icon-Tiktok;
  }
}
.icon-Time {
  &:before {
    content: $icon-Time;
  }
}
.icon-Trash {
  &:before {
    content: $icon-Trash;
  }
}
.icon-Undo {
  &:before {
    content: $icon-Undo;
  }
}
.icon-UniverseTheme {
  &:before {
    content: $icon-UniverseTheme;
  }
}
.icon-Upload {
  &:before {
    content: $icon-Upload;
  }
}
.icon-VideocamOff {
  &:before {
    content: $icon-VideocamOff;
  }
}
.icon-VideocamOn {
  &:before {
    content: $icon-VideocamOn;
  }
}
.icon-Visa {
  &:before {
    content: $icon-Visa;
  }
}
.icon-Visibility {
  &:before {
    content: $icon-Visibility;
  }
}
.icon-VisibilityOff {
  &:before {
    content: $icon-VisibilityOff;
  }
}
.icon-Voucher {
  &:before {
    content: $icon-Voucher;
  }
}
.icon-Work {
  &:before {
    content: $icon-Work;
  }
}
.icon-XClose {
  &:before {
    content: $icon-XClose;
  }
}
.icon-ZaloPay {
  &:before {
    content: $icon-ZaloPay;
  }
}
