// Version: 0.2.1
$icomoon-font-family: 'Kyons-Icon-Fonts' !default;
$icomoon-font-path: '/assets/fonts' !default;

$icon-Add: unquote('"\\e900"');
$icon-AddFile: unquote('"\\e901"');
$icon-AddFriend: unquote('"\\e902"');
$icon-AddPhoto: unquote('"\\e903"');
$icon-Alarm: unquote('"\\e904"');
$icon-AlignCenter: unquote('"\\e905"');
$icon-AlignLeft: unquote('"\\e906"');
$icon-AlignRight: unquote('"\\e907"');
$icon-ArrowBackward: unquote('"\\e908"');
$icon-ArrowDown: unquote('"\\e909"');
$icon-ArrowDownStop: unquote('"\\e90a"');
$icon-ArrowForward: unquote('"\\e90b"');
$icon-ArrowLeftStop: unquote('"\\e90c"');
$icon-ArrowRightStop: unquote('"\\e90d"');
$icon-ArrowSelector: unquote('"\\e90e"');
$icon-ArrowUp: unquote('"\\e90f"');
$icon-ArrowUpStop: unquote('"\\e910"');
$icon-ATM: unquote('"\\e911"');
$icon-BankTransfer: unquote('"\\e912"');
$icon-Bookmark: unquote('"\\e913"');
$icon-Bookmark-1: unquote('"\\e914"');
$icon-BookmarkAdd: unquote('"\\e915"');
$icon-BookmarkRemove: unquote('"\\e916"');
$icon-BulletPoint: unquote('"\\e917"');
$icon-Calendar: unquote('"\\e918"');
$icon-CalendarViewDay: unquote('"\\e919"');
$icon-CalendarViewMonth: unquote('"\\e91a"');
$icon-CalendarViewWeek: unquote('"\\e91b"');
$icon-Call: unquote('"\\e91c"');
$icon-CallTutor: unquote('"\\e91d"');
$icon-Cancel: unquote('"\\e91e"');
$icon-Carousel: unquote('"\\e91f"');
$icon-Cash: unquote('"\\e920"');
$icon-Chart: unquote('"\\e921"');
$icon-Chat: unquote('"\\e922"');
$icon-Check: unquote('"\\e923"');
$icon-ChevronDown: unquote('"\\e924"');
$icon-ChevronLeft: unquote('"\\e925"');
$icon-ChevronRight: unquote('"\\e926"');
$icon-ChevronUp: unquote('"\\e927"');
$icon-Crop: unquote('"\\e928"');
$icon-Crown: unquote('"\\e929"');
$icon-DeviceLaptop: unquote('"\\e92a"');
$icon-Document: unquote('"\\e92b"');
$icon-Done: unquote('"\\e92c"');
$icon-DotPoints: unquote('"\\e92d"');
$icon-DotsGrid: unquote('"\\e92e"');
$icon-DotsHorizontal: unquote('"\\e92f"');
$icon-DotsVertical: unquote('"\\e930"');
$icon-Draw: unquote('"\\e931"');
$icon-Duplicate: unquote('"\\e932"');
$icon-Edit: unquote('"\\e933"');
$icon-EditText: unquote('"\\e934"');
$icon-Education: unquote('"\\e935"');
$icon-Email: unquote('"\\e936"');
$icon-Eraser: unquote('"\\e937"');
$icon-Error: unquote('"\\e938"');
$icon-Facebook: unquote('"\\e939"');
$icon-File: unquote('"\\e93a"');
$icon-File01: unquote('"\\e93b"');
$icon-Filter: unquote('"\\e93c"');
$icon-Folder: unquote('"\\e93d"');
$icon-FolderOpen: unquote('"\\e93e"');
$icon-Google: unquote('"\\e93f"');
$icon-Group: unquote('"\\e940"');
$icon-Hanbook: unquote('"\\e941"');
$icon-Help: unquote('"\\e942"');
$icon-HintDocument: unquote('"\\e943"');
$icon-HintTutor: unquote('"\\e944"');
$icon-History: unquote('"\\e945"');
$icon-Home: unquote('"\\e946"');
$icon-Image: unquote('"\\e947"');
$icon-Info: unquote('"\\e948"');
$icon-InforCircle: unquote('"\\e949"');
$icon-insertText: unquote('"\\e94a"');
$icon-Inventory: unquote('"\\e94b"');
$icon-Keyboard: unquote('"\\e94c"');
$icon-KeyboardHide: unquote('"\\e94d"');
$icon-Label: unquote('"\\e94e"');
$icon-LessonContent: unquote('"\\e94f"');
$icon-Lightbulb: unquote('"\\e950"');
$icon-Lightbulb-1: unquote('"\\e951"');
$icon-LightTheme: unquote('"\\e952"');
$icon-LineWeight: unquote('"\\e953"');
$icon-Link: unquote('"\\e954"');
$icon-Location: unquote('"\\e955"');
$icon-Lock: unquote('"\\e956"');
$icon-Map: unquote('"\\e957"');
$icon-MathCamera: unquote('"\\e958"');
$icon-MenuHamburger: unquote('"\\e959"');
$icon-MessageQuestionCircle: unquote('"\\e95a"');
$icon-MessageSmileSquare: unquote('"\\e95b"');
$icon-MessageTextSquare: unquote('"\\e95c"');
$icon-MicOff: unquote('"\\e95d"');
$icon-MicOn: unquote('"\\e95e"');
$icon-Minimize: unquote('"\\e95f"');
$icon-MinusCircle: unquote('"\\e960"');
$icon-Momo: unquote('"\\e961"');
$icon-More: unquote('"\\e962"');
$icon-NewChat: unquote('"\\e963"');
$icon-NewFolder: unquote('"\\e964"');
$icon-NewLabel: unquote('"\\e965"');
$icon-NoteManagement: unquote('"\\e966"');
$icon-Notification: unquote('"\\e967"');
$icon-OpenNew: unquote('"\\e968"');
$icon-Paypal: unquote('"\\e969"');
$icon-PencilLine: unquote('"\\e96a"');
$icon-Phone: unquote('"\\e96b"');
$icon-Plus: unquote('"\\e96c"');
$icon-PlusCircle: unquote('"\\e96d"');
$icon-Present: unquote('"\\e96e"');
$icon-Preview: unquote('"\\e96f"');
$icon-Profile: unquote('"\\e970"');
$icon-ReadAll: unquote('"\\e971"');
$icon-Redo: unquote('"\\e972"');
$icon-Reload: unquote('"\\e973"');
$icon-Reset: unquote('"\\e974"');
$icon-Save: unquote('"\\e975"');
$icon-Screenshot: unquote('"\\e976"');
$icon-Search: unquote('"\\e977"');
$icon-Send: unquote('"\\e978"');
$icon-Send01: unquote('"\\e979"');
$icon-Send02: unquote('"\\e97a"');
$icon-Settings: unquote('"\\e97b"');
$icon-Share: unquote('"\\e97c"');
$icon-Shop: unquote('"\\e97d"');
$icon-Sidebar: unquote('"\\e97e"');
$icon-SignOut: unquote('"\\e97f"');
$icon-Sort: unquote('"\\e980"');
$icon-Star: unquote('"\\e981"');
$icon-Submit: unquote('"\\e982"');
$icon-Subscription: unquote('"\\e983"');
$icon-Substract: unquote('"\\e984"');
$icon-Swap: unquote('"\\e985"');
$icon-Target01: unquote('"\\e986"');
$icon-Target02: unquote('"\\e987"');
$icon-Target03: unquote('"\\e988"');
$icon-Target04: unquote('"\\e989"');
$icon-Target05: unquote('"\\e98a"');
$icon-Test: unquote('"\\e98b"');
$icon-TextBold: unquote('"\\e98c"');
$icon-TextItalic: unquote('"\\e98d"');
$icon-TextUnderlined: unquote('"\\e98e"');
$icon-Theme: unquote('"\\e98f"');
$icon-Thumbnail: unquote('"\\e990"');
$icon-ThumbsDown: unquote('"\\e991"');
$icon-ThumbsUp: unquote('"\\e992"');
$icon-Tiktok: unquote('"\\e993"');
$icon-Time: unquote('"\\e994"');
$icon-Trash: unquote('"\\e995"');
$icon-Undo: unquote('"\\e996"');
$icon-UniverseTheme: unquote('"\\e997"');
$icon-Upload: unquote('"\\e998"');
$icon-VideocamOff: unquote('"\\e99a"');
$icon-VideocamOn: unquote('"\\e99b"');
$icon-Visa: unquote('"\\e99c"');
$icon-Visibility: unquote('"\\e99d"');
$icon-VisibilityOff: unquote('"\\e99e"');
$icon-Voucher: unquote('"\\e99f"');
$icon-Work: unquote('"\\e9a0"');
$icon-XClose: unquote('"\\e9a1"');
$icon-ZaloPay: unquote('"\\e9a2"');
