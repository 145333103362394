// @use '@angular/material' as mat;

.mat-form-field-appearance-outline .mat-form-field-infix {
  // @apply px-3 py-2 h-10;
}
.mat-form-field-appearance-outline .mat-form-field-wrapper {
  @apply m-0;
}
body .mat-tooltip {
  @apply relative rounded-lg bg-primaryBlue-500 text-white p-4 text-sm;

  &::after {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid var(--color-primaryBlue);
  }
  &.below {
    overflow: initial;
    margin-top: 1rem;
    &:after {
      top: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(0);
    }
  }

  &.above {
    overflow: initial;
    margin-bottom: 1rem;
    &:after {
      bottom: -0.4rem;
      right: calc(50% - 0.5rem);
      transform: rotate(180deg);
    }
  }

  &.right {
    overflow: initial;
    margin-left: 1rem;
    &:after {
      left: -0.7rem;
      top: calc(50% - 0.25rem);
      transform: rotate(270deg);
    }
  }

  &.left {
    overflow: initial;
    margin-right: 1rem;
    &:after {
      right: -0.7rem;
      top: calc(50% - 0.25rem);
      transform: rotate(90deg);
    }
  }
}
.mdc-data-table__header-cell {
  @apply border-b-2 border-blueGray-200 #{!important};
}
/* Target Material component classes with lower specificity */
.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 24px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 0;
  .mat-mdc-button-touch-target {
    height: var(--mdc-icon-button-state-layer-size);
    width: var(--mdc-icon-button-state-layer-size);
  }
}
.material-icons {
  @apply text-xl leading-[20px];
}
.mat-icon {
  height: var(--mdc-icon-button-state-layer-size);
  width: var(--mdc-icon-button-state-layer-size);
  flex-shrink: 0;
}
.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    @apply text-sm py-2 rounded-lg;
    .mat-mdc-menu-item {
      @apply px-4 py-2 min-h-10;
      .mat-icon,
      i {
        @apply mr-2 text-blueGray-500;
      }
      .mat-mdc-menu-item-text {
        @apply flex items-center gap-2.5;
      }
    }
  }
}
.cdk-overlay-container {
  // &:empty {
  //   display: none;
  // }
  // position: fixed;
  // z-index: 1000;
  // top: 0;
  // left: 0;
  // height: 100%;
  // width: 100%;
  .cdk-overlay-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0;
  }
  .cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px;
    .cdk-overlay-pane {
      @apply absolute;
    }
  }
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box {
  @apply h-fit w-fit #{!important};
}
.mat-elevation-z8,
.mat-mdc-elevation-specific.mat-elevation-z8 {
  @apply shadow-2;
}
.mat-mdc-menu-content,
.mat-mdc-menu-panel .mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text span {
  letter-spacing: normal;
}
.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple {
  @apply overflow-hidden;
}
